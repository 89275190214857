import React from 'react';

const Projects = () => {
    return (
        <section id="projects" className="s3">
            <div className="project-container container pt-5">
                <h3 className="pt-4">Projects</h3>
                <div className="container mt-5">
                    <div className="row row-cols-1 row-cols-md-3">
                        <div className="col mb-4">
                            <div className="card h-100">
                                <img src="./images/freechat.png" className="card-img-top" alt="..."/>
                                <div className="card-body">
                                    <h5 className="card-title">Free Chat</h5>
                                    <p className="card-text">Free Chat is the easiest way to communicate over text, whether you’re part of a school club, a nightly gaming group, a worldwide art community, or just a handful of friends that want to hang out.</p>
                                    <div className="more">
                                        <a href="https://freechat-sv.herokuapp.com/" target="_blank" rel="noopener noreferrer" className="btn btn-primary">Read More</a>
                                        <a href="https://freechat-sv.herokuapp.com/" target="_blank" rel="noopener noreferrer" className="btn btn-primary">Live Demo</a>
                                        <a href="https://github.com/Shubham-Vasnik/FreeChat" target="_blank" rel="noopener noreferrer" className="btn btn-primary">Code</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col mb-4">
                            <div className="card h-100">
                                <img src="./images/visualsort.png" className="card-img-top" alt="..."/>
                                <div className="card-body">
                                    <h5 className="card-title">Visual Sort</h5>
                                    <p className="card-text">A Simple Visualizer For Sorting Algorithms. <br/> It provides a simple step by step representation of different sorting algorithms.</p>
                                    <div className="more">
                                        <a href="https://shubham-vasnik.github.io/VisualSort/" target="_blank" rel="noopener noreferrer" className="btn btn-primary">Read More</a>
                                        <a href="https://shubham-vasnik.github.io/VisualSort/" target="_blank" rel="noopener noreferrer" className="btn btn-primary">Live Demo</a>
                                        <a href="https://github.com/Shubham-Vasnik/VisualSort" target="_blank" rel="noopener noreferrer" className="btn btn-primary">Code</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col mb-4">
                            <div className="card h-100">
                                <img src="./images/passwordmanager.png" className="card-img-top" alt="..."/>
                                <div className="card-body">
                                    <h5 className="card-title">Password Manager</h5>
                                    <p className="card-text">Desktop app to manage your email and social media account passwords.</p>
                                    <div className="more">
                                        <a href="https://github.com/Shubham-Vasnik/PasswordManager" target="_blank" rel="noopener noreferrer" className="btn btn-primary">Read More</a>
                                        <a href="https://github.com/Shubham-Vasnik/PasswordManager" target="_blank" rel="noopener noreferrer" className="btn btn-primary">Live Demo</a>
                                        <a href="https://github.com/Shubham-Vasnik/PasswordManager" target="_blank" rel="noopener noreferrer" className="btn btn-primary">Code</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col mb-4">
                            <div className="card h-100">
                                <img src="./images/blog.png" className="card-img-top" alt="..."/>
                                <div className="card-body">
                                    <h5 className="card-title">Blog</h5>
                                    <p className="card-text">It is a Blog Publishing Website built using django in backend.</p>
                                    <div className="more">
                                        <a href="http://shubhamvasnik.pythonanywhere.com/" target="_blank" rel="noopener noreferrer" className="btn btn-primary">Read More</a>
                                        <a href="http://shubhamvasnik.pythonanywhere.com/" target="_blank" rel="noopener noreferrer" className="btn btn-primary">Live Demo</a>
                                        <a href="https://github.com/Shubham-Vasnik/blog_site_django" target="_blank" rel="noopener noreferrer" className="btn btn-primary">Code</a>
                                    </div>
                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Projects;