import React from 'react';

import Name from './Name';
import Navbar from './Navbar';
import Aboutme from './Aboutme';
import Projects from './Projects';
import ContactForm from './Contactme';
import Footer from './Footer';

class App extends React.Component{

    render(){
        return (
            <div>
                <Navbar/>
                <Name/>
                <Aboutme/>
                <Projects/>
                <ContactForm/>
                <Footer/>
            </div>
        );
    }

}

export default App;