import React from 'react';

const Aboutme = () => {
    return (
        <section id="about-me" className="s2">
            <div className="aboutme-container container">
                <div className="aboutme pt-5">
                    <h4>About Me</h4>
                    <p>
                        I am an energetic and passionate college student working towards 
                        <strong> B.Tech in Computer Science And Engineering at National Institute Of Technology, Patna.</strong> Currently looking for Internship Opportunities
                    </p>
                </div>
                <hr className="my-hr"/>
                <div className="expertise">
                    <h4>TOP EXPERTISE</h4>
                    <p>Fullstack developer with primary focus on Node/Django + React</p>
                    <div className="skills">
                        <div className="front-end">
                            <h5>Front-End</h5>
                            <ul className="frontend">
                                <li>HTML</li>
                                <li>CSS</li>
                                <li>SASS</li>
                                <li>JavaScript</li>
                                <li>Ajax</li>
                                <li>Jquery</li>
                            </ul>
                            <strong>Frameworks</strong>
                            <ul>
                                <li>React</li>
                                <li>Redux</li>
                                <li>Bootstrap</li>
                                <li>Semantics UI</li>
                            </ul>
                        </div>
                        
                        <div className="back-end">
                            <h5>Back-End</h5>
                            <ul>
                                <li>Node.js</li>
                                <li>Express.js</li>
                                <li>Django</li>
                                <li>Apache</li>
                                <li>Nginx</li>
                                <li>Firebase</li>
                            </ul>
                            <strong>Databases</strong>
                            <ul>
                                <li>MongoDB</li>
                                <li>SQLite</li>
                                <li>MySQL</li>
                                <li>Oracle10g</li>
                            </ul>
                            <strong>Web scraping and Automation</strong>
                            <ul>
                                <li>Beautiful Soup</li>
                                <li>Selenium</li>
                                <li>Scrapy</li>
                            </ul>
                        </div>

                        <div className="programing-languages">
                            <h5>Programming Languages</h5>
                            <ul>
                                <li>C</li>
                                <li>C++</li>
                                <li>JavaScript</li>
                                <li>Python</li>
                                <li>Java</li>
                            </ul>
                        </div>

                        <div className="machine-learning ">
                            <h5>Machine Learning </h5>
                            <ul>
                                <li>NumPy</li>
                                <li>Pandas</li>
                                <li>Scikit-learn</li>
                                <li>TensorFlow</li>
                                <li>Octave</li>
                                <li>Matlab</li>
                            </ul>
                        </div>

                        <div className="others">
                            <h5>Others</h5>
                            <ul>
                                <li>Shell scripting</li>
                                <li>Git</li>
                                <li>SDLC</li>
                                <li>Cisco packet tracer</li>
                                <li>Postman</li>
                                <li>Computer Networks</li>
                                <li>Qt C++</li>
                                <li>Ionic</li>
                                <li>Linux and Windows CLI</li>
                            </ul>
                        </div>


                </div>
                
                    
                </div>
            </div>
        </section>
    )
}

export default Aboutme;