import React from 'react';

import firebaseDb from '../firebase';

class ContactForm extends React.Component{

    state = {name:'', subject:'', email:'', message:''}

    messagesRef = firebaseDb.ref('messages');

    saveMessage = (msg) => {
        let newMessageRef = this.messagesRef.push();
        newMessageRef.set({
            name:msg.name,
            subject:msg.subject,
            email:msg.email,
            message:msg.message
        });

    }

    onNameChange = (event) =>{
        this.setState({name:event.target.value});
    }

    onSubjectChange = (event) =>{
        this.setState({subject:event.target.value});
    }

    onEmailChange = (event) =>{
        this.setState({email:event.target.value});
    }

    onMessageChange = (event) =>{
        this.setState({message:event.target.value});
    }
    

    onFormSubmit = (event) =>{
        event.preventDefault();
        let msg = {name:this.state.name, subject:this.state.subject, email:this.state.email, message:this.state.message};
        console.log(msg);
        this.saveMessage(msg);
        alert("Message Sent!");
        this.setState({name:'', subject:'', email:'', message:''});
    }

    render(){
        return (
            <section id="contact-me">
                <div className="container mt-5">
                    <div className="contact-me">
                    <h3>Get In Touch</h3>
                        <form onSubmit={this.onFormSubmit}>
                            <div className="form-group">
                                <label htmlFor="name">Name</label>
                                <input type="text" className="form-control" id="name" onChange={this.onNameChange} value={this.state.name} aria-describedby="name" required/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="subject">Subject</label>
                                <input type="text" className="form-control" id="subject" onChange={this.onSubjectChange} value={this.state.subject} aria-describedby="subject" required/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Email address</label>
                                <input type="email" className="form-control" id="email" onChange={this.onEmailChange} value={this.state.email} aria-describedby="email" required/>
                                <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                            </div>
                            <div className="form-group">
                                <label htmlFor="message">Message</label>
                                <textarea rows="3" className="form-control" id="message" onChange={this.onMessageChange} value={this.state.message} aria-describedby="message"/>
                            </div>
                            <button type="submit" className="btn btn-primary btn-block">Submit</button>
                        </form>
                    </div>
                </div>
            </section>
        )
    }

}

export default ContactForm;