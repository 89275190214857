import React from 'react';

const Footer = () => {
    return (
        <div className="footer">
            <div className="container">
                <div className="links">
                    <div>Email: <a href="mailto:shubhamvasnik@gmail.com">shubhamvasnik@gmail.com</a></div>
                    <div>Email 2: <a href="mailto:shubhamvasnik@gmail.com">shubhamv.ug17.cs@nitp.ac.in</a></div>
                    <div>GitHub: <a href="https://github.com/Shubham-Vasnik">https://github.com/Shubham-Vasnik</a></div>  
                </div>
                <div>
                    <p>This Site is built using React</p>
                    <p><a href="https://github.com/Shubham-Vasnik">Source Code</a></p>
                </div>
            </div>
        </div>
    ); 
}

export default Footer;