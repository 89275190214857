import firebase from 'firebase/app';
import 'firebase/database';

const firebaseConfig = {
    apiKey: "AIzaSyDFQBSeeG6s3t8uhVJUkT7qkqqwYqavWig",
    authDomain: "shubham-porfolio.firebaseapp.com",
    databaseURL: "https://shubham-porfolio.firebaseio.com",
    projectId: "shubham-porfolio",
    storageBucket: "shubham-porfolio.appspot.com",
    messagingSenderId: "332123233657",
    appId: "1:332123233657:web:4787ede1927eea467523a8"
  };

  const firebaseDb = firebase.initializeApp(firebaseConfig).database();

  export default firebaseDb;

