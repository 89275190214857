import React from 'react';

const Navbar = () => {
    return (
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark sticky-top">
            <a className="navbar-brand" href="https://www.shubhamvasnik.com">SV</a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
          
            <div className="collapse navbar-collapse" id="navbarSupportedContent">

            <ul className="navbar-nav mr-auto">
                <li className="nav-item">
                    <a className="nav-link" href="#about-me" >About me</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="#projects" >Projects</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="./Shubham_Vasnik_resume.pdf" target="_blank">Resume</a>
                </li>
            </ul>
              
            <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                    <a className="nav-link" href="mailto:shubhamvasnik20@gmail.com" target="_blank" rel="noopener noreferrer"><i className="fas fa-envelope"></i></a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="https://github.com/Shubham-Vasnik" target="_blank" rel="noopener noreferrer"><i className="fab fa-github"></i></a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="https://github.com/Shubham-Vasnik" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"></i></a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="#contact-me" >Contact</a>
                </li>

            </ul>
            </div>
          </nav>
    );
}

export default Navbar;